body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --dark-blue: #002147;
  --light-blue: #93b7b2;
  --citrine: #e0ca3c;
  --grape: #a799b7;
  --green: #048a81;
  --powder: #FEFEFA;
  --danger: var(--grape); }

#root {
  min-height: 100vh; }

.alert {
  padding: .75rem 1.25rem;
  border-radius: 5px;
  color: #721c24;
  background-color: rgba(248, 215, 218, 0.9);
  border: 1px solid #721c24;
  margin-bottom: 5px;
  position: absolute; }

.header .logo {
  width: 100%; }

img.separateur {
  display: block;
  max-width: 100%;
  margin: 5px 0; }

a {
  color: inherit; }
  a:not(.visible-link) {
    text-decoration: none; }

.card {
  width: 100%; }
  .card .card--content {
    padding: 5px; }
  .card .card--image {
    max-height: 100vh;
    overflow: hidden; }
    .card .card--image img {
      width: 100%; }
  .card h1 {
    margin: 0 auto;
    text-align: center; }
  .card img {
    width: 100%; }

.footer {
  text-align: center; }
  .footer .footer--links {
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: stretch;
          justify-content: stretch; }

body {
  background: #002147 url(/static/media/bkg_sd.df2e12fb.jpg) fixed bottom;
  background: var(--dark-blue) url(/static/media/bkg_sd.df2e12fb.jpg) fixed bottom;
  background-size: cover;
  color: #FEFEFA;
  color: var(--powder);
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; }

.splashScreen {
  background-color: rgba(255, 255, 255, 0.4);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100vh; }
  .splashScreen .text {
    font-weight: bold;
    color: #048a81;
    color: var(--green);
    max-width: 200px;
    font-size: 30px;
    text-align: center; }
  .splashScreen .loader {
    -webkit-animation: spin 4s linear infinite;
            animation: spin 4s linear infinite;
    font-size: 80px;
    color: #e0ca3c;
    color: var(--citrine); }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.hasDarkBackground {
  background-color: #002147; }

.hasLightBackground {
  color: #048a81;
  color: var(--green);
  background-color: white; }

.Phare__mini {
  text-decoration: unset;
  background-size: cover;
  background-position: center;
  color: #FEFEFA;
  color: var(--powder);
  text-shadow: #333333 1px 1px 1px;
  margin: 0; }
  .Phare__mini a {
    display: block;
    width: calc(100% - 20px);
    height: 100%;
    text-decoration: none;
    color: #FEFEFA;
    color: var(--powder);
    min-height: 100px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px; }
    .Phare__mini a h3 {
      margin-top: 0; }

.button {
  border-radius: 4px;
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border: 1px solid #002147;
  border: 1px solid var(--dark-blue); }
  .button.button--wiki {
    background-color: #FEFEFA;
    background-color: var(--powder);
    color: #002147;
    color: var(--dark-blue); }

.lightHouseMapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media only screen and (min-width: 768px) {
  .container {
    max-width: 1000px;
    width: 80%;
    margin: 0 auto; }
  body {
    background-image: url(/static/media/bkg_hd.f6e3d311.jpg); } }

@media only screen and (max-width: 767px) {
  .container {
    width: calc(100% - 10px);
    margin: 5px; } }

