:root {
  --dark-blue: #002147;
  --light-blue: #93b7b2;
  --citrine: #e0ca3c;
  --grape: #a799b7;
  --green: #048a81;
  --powder: #FEFEFA;
  --danger: var(--grape);
}

#root {
  min-height: 100vh;
}

.alert {
  padding: .75rem 1.25rem;
  border-radius: 5px;
  color: #721c24;
  background-color: rgba(248,215,218, .9);
  border: 1px solid #721c24;
  margin-bottom: 5px;
  position: absolute;
}

.header {
  .logo {
    width: 100%;
  }
}

img.separateur {
  display: block;
  max-width: 100%;
  margin:5px 0;
}

a {
  color: inherit;
  &:not(.visible-link) {text-decoration: none;}
}

.card {
  width: 100%;
  .card--content {
    padding: 5px;
  }
  .card--image {
    max-height: 100vh;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  h1 {
    margin: 0 auto;
    text-align: center;
  }
  img {
    width: 100%;
  }
}

.footer {
  text-align: center;
  .footer--links {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}

body {
  background: var(--dark-blue) url("../images/bkg_sd.jpg") fixed bottom;
  background-size: cover;
  color: var(--powder);
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.splashScreen {
  background-color: rgba(255,255,255, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  .text {
    font-weight: bold;
    color: var(--green);
    max-width: 200px;
    font-size: 30px;
    text-align: center;
  }
  .loader {
    animation:spin 4s linear infinite;
    font-size: 80px;
    color: var(--citrine)
  }
  @keyframes spin { 100% { transform:rotate(360deg); } }
}

.hasDarkBackground {
  background-color: rgba(0,33,71, 1);
}

.hasLightBackground {
  color: var(--green);
  background-color: rgba(255,255,255, 1);
}

.Phare__mini {
  a {
    display: block;
    width: calc(100% - 20px);
    height: 100%;
    text-decoration: none;
    color: var(--powder);
    min-height: 100px;
    background-color: rgba(0,0,0,0.2);
    padding: 10px;
    h3 {
      margin-top: 0;
    }
  }
  text-decoration: unset;
  background-size: cover;
  background-position: center;
  color: var(--powder);
  text-shadow: rgb(51, 51, 51) 1px 1px 1px;
  margin:0;
}

.button {
  border-radius: 4px;
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border: 1px solid var(--dark-blue);
  &.button--wiki {
    background-color: var(--powder);
    color: var(--dark-blue);
  }
}

.lightHouseMapper {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  @import "desktop";
}
@media only screen and (max-width: 767px) {
  @import "mobile";
}

